import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('service-category?page=' + page, {params});
    }, 
    show(id) {
        return Service().get('service-category/show/'+id);
    },
    create(params) {
        return Service().post('service-category/create', params);
    },
    update(id, params) {
        return Service().post('service-category/update/'+id, params);
    },
    delete(id) {
        return Service().post('service-category/delete/'+id);
    },
    service_group(code) {
        return Service().get('service-group/RelScategorySgroup/'+code);
    },
    add_relation(params) {
        return Service().post('service-category/add-relation/1', params);
    },
    delete_relation(id) {
        return Service().post('service-category/delete-relation/'+id);
    },
}